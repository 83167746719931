<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-25 14:46:41
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-03-07 09:30:29
 * @Description  : 堆叠区域图
-->
<template>
  <div
    id="chartContainer"
    class="stacked-area-chart"
    :style="{ height: chartHeight }"
    ref="trend"
  ></div>
</template>
<script>
import * as echarts from "echarts";
// import _ from "lodash";
export default {
  data() {
    return {
      color: [],
      legend: [],
      xData: [],
      topPosition: 0,
      bottomPosition: 0,
      unit: "",
      lineSeries: [],
      dataZoom: [],
      axisLabel: {},
      y2Height: 70,
      yAxisLeftName: "",
      yAxisRightName: "",
      tempMin: 0,
      tempMax: 30,
      wetnessMin: 0,
      wetnessMax: 100,
      leftPosition: 0,
      chartHeight: "450px",
    };
  },
  mounted() {
    this.leftPosition = this.chartWidth / 4;
    // console.log("this.leftPosition: ", this.leftPosition);

    this.initBarCharts();
  },
  methods: {
    init(LineData) {
      this.color = LineData.color;
      this.legend = LineData.legend;
      this.xData = LineData.xData;
      this.topPosition = LineData.topPosition;
      this.bottomPosition = LineData.bottomPosition;
      const totalPoints = this.xData.length;
      const maxLabels = 10;
      const interval =
        totalPoints <= maxLabels ? 0 : Math.floor(totalPoints / maxLabels) - 1;
      this.axisLabel = LineData.axisLabel;
      // console.log("interval: ", interval);

      this.axisLabel = {
        // rotate: 45, // 添加旋转角度
        // interval: 0, // 显示所有标签
        // margin: 10, // 调整标签与轴线之间的间距
        show: true,
        interval: interval,
      };
      this.unit = LineData.unit;
      this.lineSeries = LineData.lineSeries;
      this.y2Height = LineData.y2Height; //x轴文字高度
      this.yAxisLeftName = LineData.yAxis[0].name;
      this.yAxisRightName = LineData.yAxis[1].name;
      this.tempMin = LineData.yAxis[0].min;
      this.tempMax = LineData.yAxis[0].max;
      // this.wetnessMin = LineData.yAxis[1].min;
      // this.wetnessMax = LineData.yAxis[1].max;
      if (LineData.dataZoom) {
        this.dataZoom = LineData.dataZoom;
      }
      this.$nextTick(() => {
        this.initBarCharts();
      });
    },
    initBarCharts() {
      this.chart = echarts.init(this.$refs.trend);

      this.chart.setOption({
        color: this.color,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              formatter: function(params) {
                console.log(
                  "%c [ params ]-96",
                  "font-size:13px; background:pink; color:#bf2c9f;",
                  params
                );
                // 检查 params.value 是否是时间日期格式（支持 YYYY-MM-DD HH:MM 和 DD-MM-YYYY HH:MM）
                const isDateTime = /^(\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4}) \d{2}:\d{2}$/.test(
                  params.value
                );

                if (isDateTime) {
                  // 如果是时间日期格式，直接返回原值
                  return params.value;
                } else {
                  // 如果不是时间日期格式，四舍五入并保留一位小数
                  const value = parseFloat(params.value).toFixed(1);
                  return `${value}`;
                }
              },
            },
          },
        },
        grid: {
          borderColor: "transparent", // 设置边框颜色为透明
          y2: this.y2Height,
          bottom: "18%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: true },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        // 图表配置项
        animation: false,
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0],
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0],
          },
        ],
        legend: {
          data: this.legend,
          // y: "top",
          top: this.topPosition,
          bottom: this.bottomPosition,
          left: this.leftPosition,
          //'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
          // icon: "@/assets/ic_echart_alert.png",
        },
        xAxis: [
          {
            type: "category",
            data: this.xData,
            largeThreshold: 2000,
            axisLine: {
              show: true, // 是否显示轴线
              onZero: true,
            },
            axisLabel: {
              ...this.axisLabel,
              formatter: function(value) {
                // 检查value是否含有空格，如果有则假定其为分隔日期和时间的分隔符。
                const parts = value.split(" ");
                if (parts.length > 1) {
                  return parts.join("\n"); // 使用换行符连接日期和时间部分
                }
                return value;
              },
            },
            axisTick: {
              show: true,
            },
          },
        ],
        yAxis: [
          {
            // name: "Temperature (" + this.unit + ")",
            name: this.yAxisLeftName,
            type: "value",
            position: "left",
            alignTicks: true, // 配置多坐标轴标签对齐
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
              // interval: 20, // 控制刻度线的间隔
            },
            axisLabel: {
              show: true,
              // formatter: "{value}" + this.unit, //Y轴左边
              formatter: function(value) {
                return parseFloat(value).toFixed(0); // 保留一位小数
              },
            },
            nameLocation: "middle",
            nameGap: 45,
            // min: this.tempMin, // 最小值
            // max: this.tempMax, // 最大值
            // interval: Math.abs(this.tempMax - this.tempMin) / 5, // 固定间隔
          },
          {
            // name: "Humidity (%)",
            name: this.yAxisRightName,
            type: "value",
            position: "right",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 50, // 增加标签与轴线的距离
              formatter: function(value) {
                return Math.round(value) + "%"; // 四舍五入到整数
              },
            },
            nameLocation: "middle",
            nameGap: 85,
            min: this.wetnessMin, // 最小值
            max: this.wetnessMax, // 最大值
          },
        ],
        series: this.lineSeries,
      });

      // // 使用 _.once 创建一个只能调用一次的函数
      // const showAlertOnce = _.once(() => {
      //   alert("You've hovered over a legend item.");
      // });

      // let hasShownAlert = false;
      //  let lastTarget = null;
      // // 监听整个图例区域的 mousemove 事件
      // this.chart.getZr().on("mouseover", function(params) {
      //   console.log('mouseover: ');
      //   console.log("MouseMove event triggered:", params);
      //   var target = params.target;
      //   if (target && target.__itemIndex !== undefined&& !hasShownAlert&& target !== lastTarget) {
      //     console.log("00000000");
      //     showAlertOnce();
      //     hasShownAlert = true; // 设置标志位，防止再次触发
      //     lastTarget = target; // 更新最后的目标元素
      //     // 显示提示框
      //     // alert("00000000000000"); // 这里用alert代替了popover的效果
      //   }
      // });
      // // 监听鼠标离开图表区域的事件，重置标志位以便下次进入时可以再次触发提示
      // this.chart.getZr().on("mouseout", () => {
      //   console.log('mouseout: ');

      //   hasShownAlert = false; // 重置标志位，允许下次进入时触发
      //   lastTarget = null; // 重置最后的目标元素
      // });
    },

    // calculateTempMax(height) {
    //   const baseHeight = 500; // 基准高度
    //   const baseTempMax = 40; // 基准最大值
    //   const heightIncrement = 100; // 每增加100px
    //   const tempIncrement = 10; // 最大值增加10

    //   const additionalHeight = height - baseHeight;
    //   console.log("additionalHeight: ", additionalHeight);
    //   const additionalTempMax =
    //     Math.floor(additionalHeight / heightIncrement) * tempIncrement;

    //   return baseTempMax + additionalTempMax;
    // },
    changeHeight(newHeight) {
      console.log("newHeight: ", newHeight);
      this.chartHeight = newHeight;
      // const newTempMax = this.calculateTempMax(parseInt(newHeight, 10));
      // console.log("newTempMax: ", newTempMax);

      let tempIntervalCount = 0;
      let wetnessIntervalCount = 0;
      console.log("this.tempMin: ", this.tempMin);
      console.log("this.tempMax: ", this.tempMax);
      console.log("this.wetnessMin: ", this.wetnessMin);
      console.log("this.wetnessMax: ", this.wetnessMax);

      switch (newHeight) {
        case "450px":
          tempIntervalCount = (this.tempMax - this.tempMin) / 5; // 根据最大最小值计算间隔
          wetnessIntervalCount = (this.wetnessMax - this.wetnessMin) / 5;
          break;
        case "550px":
          tempIntervalCount = (this.tempMax - this.tempMin) / 10;
          wetnessIntervalCount = (this.wetnessMax - this.wetnessMin) / 10;
          break;
        case "650px":
          tempIntervalCount = (this.tempMax - this.tempMin) / 15;
          wetnessIntervalCount = (this.wetnessMax - this.wetnessMin) / 15;
          break;
        default:
          tempIntervalCount = (this.tempMax - this.tempMin) / 5; // 默认情况
          wetnessIntervalCount = (this.wetnessMax - this.wetnessMin) / 5;
      }
      console.log("tempIntervalCount: ", tempIntervalCount);

      // this.tempMax = newTempMax;

      this.$nextTick(() => {
        this.chart.setOption({
          yAxis: [
            {
              ...this.chart.getOption().yAxis[0],
              min: this.tempMin,
              max: this.tempMax,
              interval: tempIntervalCount,
            },
            {
              ...this.chart.getOption().yAxis[1],
              interval: wetnessIntervalCount,
            },
          ],
        });

        this.chart.resize(); // 强制ECharts实例重新调整大小
      });
    },
  },
  computed: {
    chartWidth() {
      return `${document.getElementById("chartContainer").clientWidth}`;
    },
  },
};
</script>
<style lang="less" scoped>
.stacked-area-chart {
  background-color: white;
  // height: 500px;
  width: 100%;
  padding: 0 10px 0 10px;
  border-radius: 8px;
}
</style>

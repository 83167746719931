<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-04 21:40:09
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-01-16 14:06:23
 * @Description  : Teams Access
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="30%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px; color: #303133">
        {{ $t("teams_with_access") }}
      </div>
    </template>
    <div class="container" v-if="options.length > 0">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item> -->
        <el-checkbox-group v-model="form.orgIds" @change="handleChange">
          <!-- 修改此处 -->
          <el-checkbox
            v-for="item in options"
            :key="item.value"
            :label="item.value"
          >
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
        <!-- </el-form-item> -->
      </el-form>
    </div>
    <div v-else>{{ $t("not_in_a_team") }}</div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deviceDetailToOrgApi, orgDeviceJoinListApi } from "@/api/api.js";

export default {
  name: "AddDeviceToTeamsDialog",
  data() {
    return {
      dialogFormVisible: false,
      deviceId: "",
      form: {
        orgId: "",
        orgIds: [],
      },
      options: [],
      keyword: "",
    };
  },
  methods: {
    showDialog(deviceId, orgId) {
      this.dialogFormVisible = true;
      this.deviceId = deviceId;
      this.form.orgIds = orgId ? [orgId] : []; // 默认选中传入的 orgId
      this.getOrgDeviceJoinList();
    },
    handleChange(value) {
      // 当选项改变时，如果选择了新的选项，则清空之前的选择，只保留当前选中的选项。
      if (value.length > 1) {
        this.$nextTick(() => {
          this.form.orgIds = [value[value.length - 1]]; // 保留最新的选择
        });
      }
    },
    handelOk() {
      // if (!this.form.orgIds.length) {
      //   this.$message.error(this.$t("please_select_one_organization"));
      //   return;
      // }
      let params = {
        deviceId: this.deviceId,
        orgId: this.form.orgIds[0], // 修改此处，取数组的第一个元素作为 orgId
      };
      deviceDetailToOrgApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },
    getOrgDeviceJoinList() {
      let params = {
        keyword: this.keyword,
      };
      orgDeviceJoinListApi(params).then((r) => {
        console.log("=====", r);
        this.options = r.data;

        // 设置默认选中的值
        if (this.form.orgIds.length) {
          this.form.orgIds =
            this.options
              .filter((option) => this.form.orgIds.includes(option.value))
              .map((option) => option.value) || [];
        }
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-07-29 19:15:07
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-03-27 16:48:23
 * @Description  : 添加评论
-->
<template>
  <el-dialog
    :title="$t('add_comment')"
    :visible.sync="dialogFormVisible"
    width="50%"
    class="font-heavy"
    :append-to-body="true"
  >
    <div class="container">
      <el-form
        :model="form"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="form.comment"
            autocomplete="off"
            :placeholder="$t('add_comment_dialog_tip')"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { setAlarmCommentApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      item: {},
    };
  },
  methods: {
    showDialog(item) {
      console.log("item: ", item);
      this.item = item;
      this.dialogFormVisible = true;
      this.form = {};
    },

    handelOk() {
      let params = {
        deviceId: this.item.deviceId,
        id: this.item.id,
        tableName: this.item.tableName,
        ...this.form,
      };
      setAlarmCommentApi(params).then((r) => {
        console.log("r", r);
        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>

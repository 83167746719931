<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-19 14:38:06
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 10:12:18
 * @Description  : 设备信息详情
-->
<template>
  <div class="container">
    <div class="top-box">
      <div class="left-box">
        <div class="record-style">
          <div
            v-if="deviceBaseInfo.alarmInd === 0"
            class="left-status-box"
            style="background-color: #20bb4c"
          >
            <!-- 是否有告警 0:无告警 1：有告警 -->
            <img :src="icShipmentZan" alt="" />
            <span> {{ $t("no_alert") }}</span>
          </div>

          <div
            v-if="deviceBaseInfo.alarmInd === 1"
            class="left-status-box"
            style="background-color: #f5582e; flex-direction: row"
          >
            <img :src="icShipmentAlert" alt="" />
            <div class="alert-text">
              <div class="text font-heavy">{{ deviceBaseInfo.alarmNum }}</div>
              <!-- <div class="text font-heavy">{{ $t("alert") }}</div> -->
            </div>
          </div>
          <span
            class="font-heavy"
            :style="
              deviceBaseInfo.networkStatus == 1
                ? 'color:#333333 '
                : 'color:#929293'
            "
            >{{ deviceBaseInfo.name }}</span
          >
          <img
            src="../../assets/ic_edit.png"
            alt=""
            @click="changeLoggerName()"
            class="edit-name"
          />
        </div>
        <div class="battery-box">
          <!-- Online/Offline/Recording/Stopped(多次性设备1停止，可以再次开始)/End(一次性设备0停止就是这个状态) -->
          <div class="device-id-style">
            {{ $t("device_id") }}: {{ deviceBaseInfo.deviceId }}
          </div>
          <div
            v-if="
              deviceBaseInfo.networkStatus == 0 ||
                deviceBaseInfo.networkStatus == null
            "
            class="status-box-gray"
          >
            {{ $t("offline") }}
          </div>
          <div v-if="deviceBaseInfo.networkStatus == 1" class="status-box-blue">
            {{ $t("online") }}
          </div>
          <BatteryInfo
            v-if="deviceDataInfo.battery && deviceDataInfo.battery.value"
            :value="deviceDataInfo.battery.value"
            :color="'#02C06F'"
            :isRight="true"
            :width="25"
            :marginLeft="'10px'"
            class="font-heavy"
          ></BatteryInfo>
        </div>
        <div class="status-box">
          <!-- <div
            class="point"
            :style="
              deviceBaseInfo.status == 0 ||
              deviceBaseInfo.status == 3 ||
              deviceBaseInfo.status == 4 ||
              deviceBaseInfo.status == 9
                ? 'background: #ed6e36;'
                : 'background: #02C06F;'
            "
          ></div> -->
          <span
            v-if="deviceBaseInfo.status == 0"
            style="color: #02c06f; font-size: 20px"
          >
            {{ $t("not_started") }}
          </span>
          <span
            v-if="deviceBaseInfo.status == 1"
            style="color: #02c06f; font-size: 20px"
            >{{ $t("recording") }}</span
          >
          <span
            v-if="deviceBaseInfo.status == 2"
            style="color: #02c06f; font-size: 20px"
          >
            {{ $t("delay_recording") }}
          </span>
          <span
            v-if="deviceBaseInfo.status == 3 && deviceBaseInfo.cycleInd == 0"
            style="color: #ed6e36; font-size: 20px"
            >{{ $t("ended") }}</span
          >
          <!-- && deviceBaseInfo.cycleInd == 0 -->
          <span
            v-if="deviceBaseInfo.status == 4"
            style="color: #ed6e36; font-size: 20px"
            >{{ $t("stopped") }}</span
          >
          <span
            v-if="deviceBaseInfo.status == 9"
            style="color: #ed6e36; font-size: 20px"
            >{{ $t("discard") }}</span
          >
        </div>
        <div class="time-box">
          <span>
            {{ $t("started_at") }}:
            {{
              deviceBaseInfo.startTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </span>
          <span>
            {{ $t("end_at") }}:
            {{
              deviceBaseInfo.endTime
                | formatDateWithTimeZone(
                  setSelectedTimeZone,
                  setSelectedLanguage
                )
            }}
          </span>
        </div>

        <FunctionBox
          :deviceBaseInfo="deviceBaseInfo"
          :deviceId="deviceId"
          :cycleInd="cycleInd"
          :shareInd="shareInd"
          @getDetailInfo="getDetailInfo"
          @goHomeBoard="goHomeBoard"
        ></FunctionBox>

        <MeasurementsData :deviceDataInfo="deviceDataInfo"></MeasurementsData>
      </div>

      <div class="right-box">
        <div class="operate-box">
          <div class="btn-box">
            <!-- <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="addToOrganization"
            >
              {{ $t("add_to_organization") }}
            </el-button> -->
            <!-- <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="addToScene"
            >
              {{ $t("add_to_scene") }}
            </el-button> -->
            <!-- <el-button
              class="btn-style"
              :class="{ 'limits-1': limits === 1, 'limits-0': limits === 0 }"
              :disabled="limits === 0"
              @click="transferDevice"
            >
              {{ $t("transfer_device") }}
            </el-button> -->
            <el-button
              class="btn-style"
              :class="{
                'limits-1': transferEnabled === 1,
                'limits-0': transferEnabled === 0,
              }"
              :disabled="transferEnabled === 0"
              @click="transferDevice"
            >
              {{ $t("transfer_device") }}
            </el-button>
          </div>
        </div>
        <div class="table-container">
          <table border="0" cellspacing="0" style="border-collapse: collapse">
            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("logger_name") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.name
                }}<img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="changeLoggerName()"
                />
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("device_type") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                <span v-if="deviceBaseInfo.cycleInd === 0">{{
                  $t("single_use")
                }}</span>
                <span v-if="deviceBaseInfo.cycleInd === 1">{{
                  $t("multi_use")
                }}</span>
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">{{ $t("remark") }}</td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.remark }}
                <img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="changeLoggerRemark()"
                />
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">{{ $t("owner") }}</td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.owner }}
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">{{ $t("model") }}</td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.model }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("measurement_interval") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.recordingMin | UploadInterval }}
              </td>
            </tr>

            <tr>
              <td class="white-cell label font-heavy">
                {{ $t("upload_interval") }}
              </td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ deviceBaseInfo.uploadMin | UploadInterval }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("teams_with_access") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ orgInfo.name || "-"
                }}<img
                  src="@/assets/ic_edit.png"
                  alt=""
                  @click="teamsAccess()"
                />
              </td>
            </tr>
            <tr>
              <td
                class="white-cell label font-heavy"
                style="border-bottom: none !important"
              >
                {{ $t("shipment") }}
              </td>
              <td
                class="white-cell"
                style="
                  width: 250px;
                  border-right: none !important;
                  border-bottom: none !important;
                "
              >
                <div class="shipment-detail" v-if="deviceBaseInfo.shipmentName">
                  <div class="btn-box" @click="goShipmentDetail">
                    {{ deviceBaseInfo.shipmentName }}
                  </div>
                </div>

                <div
                  v-if="!deviceBaseInfo.shipmentName"
                  class="shipment-operate"
                >
                  <span>{{ $t("not_in_a_shipment") }}</span>
                  <div class="btn-box" @click="newShipment">
                    +{{ $t("new_shipment") }}
                  </div>
                  <div class="btn-box" @click="attachToShipment">
                    {{ $t("attach_to_existing_shipment") }}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="echart-box">
      <span class="font-heavy">{{ $t("historical_data_chart") }}</span>
      <div class="zoom-style">
        <div class="left">
          <div>{{ $t("zoom") }}</div>
          <div class="tab-box">
            <div
              class="tab-item"
              :class="isActive == 1 ? 'is-active' : ''"
              @click="clickTab(1)"
            >
              {{ $t("1_day") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 2 ? 'is-active' : ''"
              @click="clickTab(2)"
            >
              {{ $t("1_week") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 3 ? 'is-active' : ''"
              @click="clickTab(3)"
            >
              {{ $t("1_month") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 4 ? 'is-active' : ''"
              @click="clickTab(4)"
            >
              {{ $t("1_year") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 5 ? 'is-active' : ''"
              @click="clickTab(5)"
            >
              {{ $t("all") }}
            </div>

            <div class="zoom-box">
              {{ $t("select_chart_height") }}:
              <el-select v-model="zoomOptionsValue" @change="changeZoomOption">
                <el-option
                  v-for="item in zoomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="date-tip">{{ $t("from") }}</div>
          <el-date-picker
            v-model="startDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('start_date')"
            @change="onChangeStart"
          >
          </el-date-picker>
          <div class="date-tip">{{ $t("to") }}</div>
          <el-date-picker
            v-model="endDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('end_date')"
            @change="onChangeEnd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="chart">
        <LoggerStackedAreaChart
          ref="chartRef"
          class="chart-item"
        ></LoggerStackedAreaChart>

        <div
          class="additional-div"
          :style="{ 'margin-right': `calc(100% - ${chartWidth})` }"
        >
          <img src="../../assets/ic_echart_alert.png" alt="" />
          <div v-if="!this.tempConfig && !this.wetnessConfig" class="all-null">
            <span> {{ $t("no_alert_rules") }}</span>
          </div>
          <div v-if="this.tempConfig || this.wetnessConfig" class="not-null">
            <div class="high">High <span> - -</span></div>
            <div class="low">Low <span> - -</span></div>
          </div>
        </div>
      </div>
      <el-button
        type="primary"
        @click="downloadExcel"
        style="margin-top: 10px"
        >{{ $t("view_as_list_or_download_excel") }}</el-button
      >
    </div>

    <div class="rules-box">
      <div class="rules-title">
        <div class="left">
          <span class="font-heavy"> {{ $t("alert_rules_key") }}</span>
          <!-- （1）没有报警规则，且不可设置，没有设置报警规则 且不允许设置新的报警规则时 -->
          <div
            v-if="
              form.detailList &&
                form.detailList.length <= 0 &&
                rulesEnabled == 0
            "
          >
            <span class="font-light"> {{ $t("no_alert_rules") }}.</span>
            <span class="font-light"> {{ $t("logger_started_tip") }}</span>
          </div>
          <!-- 没有报警规则，可以设置新的 -->
          <div
            v-if="
              form.detailList &&
                form.detailList.length <= 0 &&
                rulesEnabled == 1
            "
          >
            <span class="font-light"> {{ $t("no_alert_rules_tip") }}</span>
          </div>

          <span
            class="font-light"
            v-if="form.detailList && form.detailList.length > 0"
          >
            {{ $t("alert_rules_tip_key") }}</span
          >
        </div>
        <div class="right">
          <el-button
            type="primary"
            @click="selectExistedRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.5;' : ''
            "
            >{{ $t("select_existed_rules") }}</el-button
          >
          <el-button
            type="primary"
            @click="newRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.5;' : ''
            "
            >+{{ $t("new_rules") }}</el-button
          >
        </div>
      </div>

      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item
          v-for="(rule, index) in form.detailList"
          :key="rule.key"
          :prop="'detailList.' + index + '.value'"
        >
          <div class="rule-box">
            <div class="selects-container">
              <img
                v-if="rulesActived == 0"
                src="@/assets/ic_rule_alert.png"
                alt=""
              />
              <el-select v-model="rule.type" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmType"
                  :key="item.value"
                ></el-option>
              </el-select>
              <el-select v-model="rule.source" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in alarmSensor"
                  :key="index"
                ></el-option>
              </el-select>
              <!-- <el-select v-model="rule.conditions" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmConditions"
                  :key="item.value"
                ></el-option>
              </el-select> -->

              <!-- <el-input
                v-model="rule.value"
                class="input-item"
                disabled
              ></el-input> -->

              <el-input
                class="input-item custom-placeholder"
                placeholder="less than"
                disabled
                v-if="!showAfterOrSelector(rule)"
              >
              </el-input>

              <el-input
                v-model.number="rule.min"
                class="input-item"
                disabled
                v-if="!showAfterOrSelector(rule)"
              ></el-input>
              <div v-if="showOrTextSelector(rule)">OR</div>
              <el-input
                class="input-item custom-placeholder"
                placeholder="more than"
                disabled
                v-if="!showBeforeOrSelector(rule)"
              >
              </el-input>

              <el-input
                v-model.number="rule.max"
                class="input-item"
                disabled
                v-if="!showBeforeOrSelector(rule)"
              ></el-input>
              <el-input
                v-model="rule.unit"
                class="input-item"
                disabled
                v-if="!showTemperatureUnit(rule)"
              >
              </el-input>
              <div v-if="showDurationSelector(rule)">
                For more than
                <el-input
                  v-model.number="rule.duration"
                  disabled
                  class="input-item"
                ></el-input>
                <el-input
                  placeholder="Minutes"
                  class="input-item custom-placeholder"
                  disabled
                  style="margin-left: 8px"
                ></el-input>
              </div>
            </div>
            <img
              v-if="rulesEnabled != 0"
              @click.prevent="removeRule(rule)"
              src="../../assets/ic_cross.png"
              alt=""
            />
          </div>
        </el-form-item>
      </el-form>
      <!-- 0:未生效 1：已生效 9：无告警规则，显示同1已生效 -->
      <!-- <div v-if="rulesActived == 0">
        {{
          this.form.detailList.length > 0
            ? $t("alert_rule_not_take_effect")
            : $t("no_alert_rules")
        }}
      </div>
      <div v-if="rulesActived == 1">
        {{ $t("alert_rule_take_effect") }}
      </div> -->

      <div v-if="this.form.detailList">
        {{
          rulesActived == 1
            ? $t("alert_rule_take_effect")
            : $t("alert_rule_not_take_effect")
        }}
      </div>
      <div v-if="!this.form.detailList">
        {{ $t("no_alert_rules") }}
      </div>
    </div>

    <div class="alert-box">
      <div class="alert-title">
        <span class="font-heavy">{{ $t("alert") }}</span>
        <el-button type="primary" @click="setRead">{{
          $t("mark_selected_alerts_as_checked")
        }}</el-button>
      </div>
      <!-- <div class="table-title">{{ $t("showing") }}</div> -->

      <el-table
        :data="alarmList"
        style="width: 100%"
        stripe
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        ref="alarmTable"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleAlarmSelectionChange"
        :empty-text="$t('no_data')"
        :row-style="getRowStyle"
      >
        <el-table-column prop="content" :label="$t('cause')">
          <template v-slot:default="scope">
            <span> {{ scope.row.content || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" :label="$t('alarm_time')">
          <template v-slot:default="scope">
            <span>
              {{
                scope.row.createdDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="readInd" :label="$t('checked')">
          <template v-slot:default="scope">
            <span v-if="scope.row.readInd === 0"> {{ $t("KEY_NO") }}</span>
            <span v-if="scope.row.readInd === 1"> {{ $t("KEY_YES") }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="comment" :label="$t('comments')">
          <template v-slot:default="scope">
            <div class="comment-box" v-if="!scope.row.comment">
              <el-button
                type="primary"
                class="add-comment"
                @click="addComment(scope.row)"
                style="
                  background: transparent;
                  border: 1px solid transparent;
                  color: #1a72f4;
                  text-decoration: underline;
                "
              >
                +{{ $t("add_comment") }}</el-button
              >
            </div>
            <div v-else-if="scope.row.comment">{{ scope.row.comment }}</div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55"   :selectable="rowSelectable"> </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <StatisticsBox :totalList="totalList"></StatisticsBox>

    <SummaryBox
      :summaryDuration="summaryDuration"
      :summaryRecordTotal="summaryRecordTotal"
      :summaryAlarmTotal="summaryAlarmTotal"
    ></SummaryBox>

    <ChangeLoggerNameDialog
      ref="nameRef"
      @refresh="getDetailInfo"
    ></ChangeLoggerNameDialog>
    <ChangeLoggerRemarkDialog
      ref="remarkRef"
      @refresh="getDetailInfo"
    ></ChangeLoggerRemarkDialog>
    <!-- <ChangeAlarmConditionsDialog ref="editRef" @refresh="getAlarmRules">
    </ChangeAlarmConditionsDialog> -->
    <TransferDeviceDialog ref="transferRef"></TransferDeviceDialog>
    <!-- <AddDeviceToSceneDialog ref="deviceToSceneRef"></AddDeviceToSceneDialog> -->
    <AddDeviceToOrganDialog ref="deviceToOrganRef"></AddDeviceToOrganDialog>
    <AddDeviceToTeamsDialog
      ref="deviceToTeamsRef"
      @ok="getDetailInfo"
    ></AddDeviceToTeamsDialog>

    <AddCommentDialog
      ref="commentRef"
      @refresh="getAlarmList"
    ></AddCommentDialog>

    <SelectExistedRulesDialog
      ref="existedRulesRef"
      @refresh="getAlarmRules"
    ></SelectExistedRulesDialog>
    <ShowCanAddShipmentListDialog
      ref="shipmentRef"
      @refresh="getDetailInfo"
    ></ShowCanAddShipmentListDialog>
  </div>
</template>

<script>
import ChangeLoggerNameDialog from "./modal/ChangeLoggerNameDialog.vue";
import ChangeLoggerRemarkDialog from "./modal/ChangeLoggerRemarkDialog.vue";
import LoggerStackedAreaChart from "../components/LoggerStackedAreaChart.vue";
// import ChangeAlarmConditionsDialog from "./modal/ChangeAlarmConditionsDialog.vue";
import TransferDeviceDialog from "./modal/TransferDeviceDialog.vue";
// import AddDeviceToSceneDialog from "./modal/AddDeviceToSceneDialog.vue";
import AddDeviceToOrganDialog from "./modal/AddDeviceToOrganDialog.vue";
import AddCommentDialog from "./modal/AddCommentDialog.vue";
import SelectExistedRulesDialog from "./modal/SelectExistedRulesDialog.vue";
import AddDeviceToTeamsDialog from "./modal/AddDeviceToTeamsDialog.vue";
import ShowCanAddShipmentListDialog from "../logger/modal/ShowCanAddShipmentListDialog.vue";
import BatteryInfo from "../components/BatteryInfo.vue";
import FunctionBox from "./components/FunctionBox.vue";
import StatisticsBox from "./components/StatisticsBox.vue";
import SummaryBox from "./components/SummaryBox.vue";
import MeasurementsData from "./components/MeasurementsData.vue";

import moment from "moment";

import {
  setAlarmReadApi,
  getDetailInfoApi,
  getDetailLineApi,
  getDetailSummaryApi,
  getAlarmRulesApi,
  getAlarmListApi,
  selectApi,
  deleteAlarmSettingApi,
} from "@/api/api.js";

import _ from "lodash";

export default {
  name: "LoggerDetail",
  data() {
    return {
      shareInd: 0, //是否分享设备标识 1:是，0:否
      icShipmentAlert: require("@/assets/ic_shipment_alert.png"),
      icShipmentZan: require("@/assets/ic_shipment_zan.png"),
      networkStatus: 0,
      zoomValue: 40,
      zoomOptions: [
        { value: "1", label: "1x" },
        { value: "2", label: "2x" },
        { value: "3", label: "3x" },
      ],
      zoomOptionsValue: "1",
      // chartHeight: "500px",
      deviceBaseInfo: {},
      deviceDataInfo: {},
      form: { detailList: {} },
      sceneInfo: { name: "" },
      orgInfo: {},
      option: {
        color: ["#76BFF5", "#50D69C"],
        legend: ["", ""],
        xData: [],
        unit: "℃",
        axisLabel: {
          show: true, // 是否显示刻度标签，默认显示
          interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；
        },
        yAxis: [{ name: "", min: 0, max: 50 }, { name: "" }],
        y2Height: 70,
        y1Name: "",
        x1Name: "",
        lineSeries: [
          {
            name: "",
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.1,
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                },
              },
            },
            data: [],
            markLine: {
              data: [],
            },
          },
          {
            name: "",
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.1,
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                },
              },
            },
            data: [],
            markLine: {
              data: [],
            },
          },
        ],
      },
      alarmList: [],
      isActive: 1,
      startDate: "",
      endDate: "",
      deviceIds: [],
      alarmSelection: [],
      lineDataList: [],
      type: 1, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      summaryAlarmTotal: 0,
      summaryDuration: "",
      summaryRecordTotal: 0,
      totalList: [],
      rulesActived: 0, // 0:未生效 1：已生效 9：无告警规则，显示同1已生效
      alarmType: [],
      alarmSensor: [],
      alarmConditions: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      timezone: 0,
      isChina: true,
      limits: "",
      deviceId: "",
      cycleInd: 0, //是否重复使用设备标识 1:是，0:否 0时告警规则不允许编辑
      rulesEnabled: 0,
      startTime: "",
      endTime: "",
      tempConfig: {},
      wetnessConfig: {},
      transferEnabled: 0,
    };
  },
  methods: {
    changeLoggerName() {
      this.$refs.nameRef.showDialog(this.deviceId, this.deviceBaseInfo.name);
    },
    changeLoggerRemark() {
      this.$refs.remarkRef.showDialog(
        this.deviceId,
        this.deviceBaseInfo.remark
      );
    },

    // 辅助函数：计算跨月或跨年的一个月/年前/后日期，并调整为前一天
    calculateDateByInterval(date, interval, unit) {
      let result = moment(date).add(interval, unit);
      // 如果目标时间单位没有对应的日期，则调整到该时间单位的最后一日
      if (date.date() !== result.date()) {
        if (unit === "months") {
          result.endOf("month");
        } else if (unit === "years") {
          result.endOf("year");
        }
      }
      return result.subtract(1, "days"); // 调整为前一天
    },

    // 辅助函数：计算跨月或跨年的前一个月/年前的对应日期，并调整为后一天
    calculatePreviousCorrespondingDate(date, unit) {
      let previousDate = moment(date).subtract(1, unit);
      // 如果目标时间单位没有对应的日期，则调整到该时间单位的第一天之后的一天
      if (date.date() !== previousDate.date()) {
        previousDate
          .startOf(unit === "months" ? "month" : "year")
          .add(1, "days");
      } else {
        previousDate.add(1, "days"); // 确保是前一天的后一天
      }
      return previousDate;
    },

    onChangeStart(value) {
      this.startDate = value;

      switch (this.type) {
        case 1: // Day
          this.endDate = this.startDate; // 日选项下，from 和 to 应该保持一致
          break;
        case 2: // Week
          this.endDate = moment(this.startDate)
            .clone()
            .add(6, "days")
            .format("YYYY-MM-DD");
          break;
        case 3: // Month
          this.endDate = this.calculateDateByInterval(
            moment(this.startDate),
            1,
            "months"
          ).format("YYYY-MM-DD");
          break;
        case 4: // Year
          this.endDate = this.calculateDateByInterval(
            moment(this.startDate),
            1,
            "years"
          ).format("YYYY-MM-DD");
          break;
        default:
          // this.endDate = ""; // 对于全部选项，不自动设置结束日期
          break;
      }
      this.getDetailLine();
    },

    onChangeEnd(value) {
      this.endDate = value;

      switch (this.type) {
        case 1: // Day
          this.startDate = this.endDate; // 日选项下，from 和 to 应该保持一致
          break;
        case 2: // Week
          this.startDate = moment(this.endDate)
            .clone()
            .subtract(6, "days")
            .format("YYYY-MM-DD");
          break;
        case 3: // Month
          this.startDate = this.calculatePreviousCorrespondingDate(
            moment(this.endDate),
            "months"
          ).format("YYYY-MM-DD");
          break;
        case 4: // Year
          this.startDate = this.calculatePreviousCorrespondingDate(
            moment(this.endDate),
            "years"
          ).format("YYYY-MM-DD");
          break;
        default:
          // this.startDate = ""; // 对于全部选项，不自动设置开始日期
          break;
      }
      this.getDetailLine();
    },
    clickTab(idx) {
      this.isActive = idx;
      let now = moment(); // 使用当前时间

      switch (idx) {
        case 1: // Day
          this.type = 1;
          this.startDate = now.format("YYYY-MM-DD");
          this.endDate = now.format("YYYY-MM-DD");
          break;
        case 2: // Week
          this.type = 2;
          this.endDate = now.format("YYYY-MM-DD");
          this.startDate = now
            .clone()
            .subtract(6, "days")
            .format("YYYY-MM-DD"); // Add 6 days for a week
          break;
        case 3: // Month
          this.type = 3;
          this.endDate = now.format("YYYY-MM-DD");
          this.startDate = this.calculatePreviousCorrespondingDate(
            moment(this.endDate),
            "months"
          ).format("YYYY-MM-DD");
          // this.calculateDateByInterval(now, 1, "months").format(
          //   "YYYY-MM-DD"
          // );
          break;
        case 4: // Year
          this.type = 4;
          this.endDate = now.format("YYYY-MM-DD");
          this.startDate = this.calculatePreviousCorrespondingDate(
            moment(this.endDate),
            "years"
          ).format("YYYY-MM-DD");
          // this.calculateDateByInterval(now, 1, "years").format(
          //   "YYYY-MM-DD"
          // );
          break;
        case 5: // All
          this.type = 9;
          this.startDate = "";
          this.endDate = "";
          break;
      }

      console.log(
        `Type ${this.type}: Start Date - ${this.startDate}, End Date - ${this.endDate}`
      );
      this.getDetailLine();
      // this.changeZoomOption(this.zoomOptionsValue);
    },

    transferDevice() {
      this.$refs.transferRef.showDialog(this.deviceId);
    },
    addComment(item) {
      this.$refs.commentRef.showDialog(item);
    },

    teamsAccess() {
      this.$refs.deviceToTeamsRef.showDialog(this.deviceId, this.orgInfo.orgId);
    },
    getAlarmList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        timezone: this.timezone,
        // startDate: this.startDate,
        // endDate: this.endDate,
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
        deviceId: this.deviceId,
      };
      getAlarmListApi(params).then((r) => {
        console.log("获取告警详情列表=====", r);
        this.alarmList = r.data.records;
        this.total = r.data.total;
        console.log("告警详情列表数据条数: ", r.data.length);
      });
    },

    getDetailSummary() {
      getDetailSummaryApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取温湿度统计以及summary信息=====", r);
        this.totalList = r.data.totalList;
        this.summaryAlarmTotal = r.data.summaryAlarmTotal;
        this.summaryDuration = r.data.summaryDuration;
        this.summaryRecordTotal = r.data.summaryRecordTotal;
      });
    },

    getDetailLine() {
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
        // startDate: this.startDate,
        // endDate: this.endDate,
        startDate: moment(this.startDate).unix(),
        endDate: moment(this.endDate)
          .endOf("day")
          .unix(),
        type: this.type, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
        startDateTimestamp: 0,
      };
      getDetailLineApi(params).then((r) => {
        console.log("获取折线图数据=====", r);
        this.lineDataList = r.data.lineDataList || [];
        // console.log("this.lineDataList: ", this.lineDataList);
        // if (this.lineDataList.length <= 0) {
        //   this.$message.success(this.$t("no_data"));
        // }
        let gatherDateArr = [];
        this.lineDataList.forEach((element) => {
          // console.log("element: ", JSON.stringify(element));
          gatherDateArr.push(
            this.$options.filters.formatDateWithTimeZone(
              element.gatherTimestamp,
              this.setSelectedTimeZone,
              this.setSelectedLanguage
            )
          );
        });

        this.option.topPosition = 15;
        this.option.xData = gatherDateArr;
        console.log(
          "%c [ gatherDateArr ]-1109",
          "font-size:13px; background:pink; color:#bf2c9f;",
          gatherDateArr
        );
        this.option.yAxis[0].name = this.$t("temperature");
        this.option.yAxis[1].name = this.$t("humidity");
        this.option.yAxis[0].yAxisIndex = 0;
        this.option.yAxis[1].yAxisIndex = 1;

        this.option.legend[0] =
          this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        this.option.legend[1] = this.$t("humidity") + "(%)";

        //处理温度
        let tempArr = [];
        // 收集温度数据并应用过滤器
        tempArr = this.lineDataList.map((element) => {
          return this.$options.filters.convertTemperature(
            Number(element.temp),
            this.setSelectedTempType
          );
        });
        console.log("tempArr: ", tempArr);
        this.option.lineSeries[0].data = tempArr;

        this.option.lineSeries[0].yAxisIndex = 0;
        this.option.lineSeries[0].name =
          this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        this.option.unit = this.setSelectedTempType;

        this.option.yAxis[0].min = Math.min(...tempArr) - 10;
        console.log("this.option.yAxis[0].min: ", this.option.yAxis[0].min);
        this.option.yAxis[0].max = Math.max(...tempArr);
        console.log("this.option.yAxis[0].max: ", this.option.yAxis[0].max) +
          10;

        //处理湿度
        // let wetnessArr = [];
        // this.lineDataList.forEach((element) => {
        //   wetnessArr.push(Math.round(element.wetness));
        // });
        let wetnessArr = this.lineDataList.map((element) => {
          return Math.round(element.wetness);
        });
        console.log("wetnessArr: ", wetnessArr);
        this.option.lineSeries[1].data = wetnessArr;
        this.option.lineSeries[1].yAxisIndex = 1;
        this.option.lineSeries[1].name = this.$t("humidity") + "(%)";

        this.tempConfig = r.data.tempConfig;

        this.wetnessConfig = r.data.wetnessConfig;
        //处理温度参考线
        if (this.tempConfig) {
          const max = this.tempConfig.max;
          console.log("max: ", max);
          const min = this.tempConfig.min;
          console.log("min: ", min);
          this.option.yAxis[0].min = min - 10;
          this.option.yAxis[0].max = this.zoomValue;
          // 添加固定数值的参考线
          this.option.lineSeries[0].markLine.data.push(
            {
              yAxis: max, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: ({ value }) => this.getFormattedTemp(value),
                },
              },
              lineStyle: {
                color: "red",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            },
            {
              yAxis: min, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: ({ value }) => this.getFormattedTemp(value),
                },
              },
              lineStyle: {
                color: "blue",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            }
          );
        } else {
          // this.option.yAxis[0].min =
          //   tempArr.length == 0 ? 0 : Math.min(...tempArr) - 10;
          // console.log("this.option.yAxis[0].min: ", this.option.yAxis[0].min);
          // this.option.yAxis[0].max =
          //   tempArr.length == 0 ? 100 : Math.max(...tempArr);
          // console.log("this.option.yAxis[0].max: ", this.option.yAxis[0].max) +
          //   10;
        }

        //处理湿度参考线
        if (this.wetnessConfig) {
          const max = this.wetnessConfig.max;
          console.log("max: ", max);
          const min = this.wetnessConfig.min;
          console.log("min: ", min);
          this.option.yAxis[1].min = min;
          this.option.yAxis[1].max = max;
          // 添加固定数值的参考线
          this.option.lineSeries[1].markLine.data.push(
            {
              yAxis: max, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: max + "%",
                },
              },
              lineStyle: {
                color: "red",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            },
            {
              yAxis: min, // 指定y轴上的位置为10
              symbol: "none", // 设置两端符号都为 'none'
              // symbolSize: 0, // 可选，定义符号大小
              label: {
                normal: {
                  // show: true,
                  position: "end",
                  formatter: min + "%",
                },
              },
              lineStyle: {
                color: "blue",
                // width: 2,
                type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
              },
            }
          );
        } else {
          //   this.option.yAxis[1].min = Math.min(...wetnessArr) || 0;
          //   console.log("wetnessArr: ", JSON.stringify(wetnessArr));
          //   console.log("this.option.yAxis[1].min: ", this.option.yAxis[1].min);
          //   this.option.yAxis[1].max = Math.max(...wetnessArr) || 100;
          //   console.log("this.option.yAxis[1].max: ", this.option.yAxis[1].max);
          this.option.yAxis[1].min = Math.min(...wetnessArr) || 0;
          console.log(" Math.min(...wetnessArr): ", Math.min(...wetnessArr));
          // console.log("wetnessArr: ", JSON.stringify(wetnessArr));
          // console.log("this.option.yAxis[1].min: ", this.option.yAxis[1].min);
          this.option.yAxis[1].max = Math.max(...wetnessArr) || 100;
          console.log(" Math.max(...wetnessArr) : ", Math.max(...wetnessArr));
          // console.log("this.option.yAxis[1].max: ", this.option.yAxis[1].max);
        }
        this.option.legendTop = 15;
        this.$nextTick(() => {
          this.$refs.chartRef.init(this.option);
        });
      });
    },

    goHomeBoard() {
      localStorage.setItem("selectedMenuItem", 1);
      this.$store.dispatch("updateSelectedMenuItem", 1);
      this.goPages("/home/board");
    },
    getDetailInfo() {
      getDetailInfoApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取设备基础信息=====", r);
        this.deviceBaseInfo = r.data;
        this.deviceDataInfo = r.data.deviceDataInfo; //设备最新数据信息
        this.orgInfo = r.data.orgInfo || {};
        this.sceneInfo = r.data.sceneInfo || {};
        this.limits = r.data.limits;
        this.shareInd = r.data.shareInd;
        this.cycleInd = r.data.cycleInd;
        this.rulesEnabled = r.data.rulesEnabled;
        this.rulesActived = r.data.rulesActived;
        console.log(" this.rulesActived: ", this.rulesActived);
        this.startTime = r.data.startTime;
        this.endTime = r.data.endTime;
        this.status = r.data.status;
        this.transferEnabled = r.data.transferEnabled;
      });
    },

    getAlarmRules() {
      getAlarmRulesApi({ deviceId: this.deviceId }).then((r) => {
        console.log("获取告警规则=====", r);
        this.form.detailList =
          r.data &&
          r.data.map((item) => {
            if (item.unit === "c" || item.unit === "C") {
              item.unit = "°C";
            } else if (item.unit === "f" || item.unit === "F") {
              item.unit = "°F";
            }
            item.source = String(item.source);
            item.type = String(item.type);
            return item;
            // 保留原始 unit 字段
            //   const originalUnit = item.unit;

            //   // 设置显示的 unit
            //   if (originalUnit === "c") {
            //     item.displayUnit = "°C";
            //   } else if (originalUnit === "f") {
            //     item.displayUnit = "°F";
            //   }

            //   return item;
          });

        console.log(
          "getAlarmRules---this.form.detailList: ",
          this.form.detailList
        );

        this.$forceUpdate();
      });
    },

    downloadExcel() {
      let obj = {
        deviceId: this.deviceId,
        name: this.deviceBaseInfo.name,
        isShipment: false,
        startTime: this.startTime,
        endTime: this.endTime,
        status: this.status,
      };
      console.log("obj: ", obj);

      this.goPages("/loggerInfoExport", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    // edit() {
    //   this.$refs.editRef.showDialog("", this.form.detailList, this.deviceId);
    // },

    // addToScene() {
    //   this.$refs.deviceToSceneRef.showDialog(this.deviceId);
    // },
    convertToTimestamp(dateString) {
      // 使用 moment 解析日期字符串
      const date = moment(dateString, "YYYY-MM-DD HH:mm");
      // 检查解析是否成功
      if (!date.isValid()) {
        console.error("Invalid date string:", dateString);
        return null;
      }
      // 获取时间戳（以毫秒为单位）
      const timestamp = date.valueOf();
      // 如果需要秒级时间戳，可以除以1000
      const timestampInSeconds = Math.floor(timestamp / 1000);
      return timestampInSeconds; // 或者返回 timestampInSeconds 如果你需要的是秒级时间戳
    },
    // addToOrganization() {
    //   this.$refs.deviceToOrganRef.showDialog(this.deviceId);
    // },

    toggleLoggerSelection(rows) {
      console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.alarmTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.alarmTable.clearSelection();
      }
    },
    handleAlarmSelectionChange(val) {
      console.log("val: ", val.deviceId);
      this.alarmSelection = val;
    },
    setRead() {
      console.log(" this.alarmSelection: ", this.alarmSelection);
      if (this.alarmSelection.length <= 0) {
        this.$message.error(this.$t("please_select_alert_first"));
        return;
      }
      let temp = [];
      this.alarmSelection.forEach((element) => {
        temp.push(element.id);
      });
      let params = {
        idList: temp,
        deviceId: this.deviceId,
      };
      setAlarmReadApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        this.getAlarmList();
      });
    },
    getAlarmType() {
      selectApi({ type: "AlarmType" }).then((r) => {
        this.alarmType = r.data;
      });
    },
    getAlarmSensor() {
      selectApi({ type: "AlarmSensor" }).then((r) => {
        this.alarmSensor = r.data;
      });
    },
    getAlarmConditions() {
      selectApi({ type: "AlarmConditions" }).then((r) => {
        this.alarmConditions = r.data;
      });
    },
    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getAlarmList();
    },
    handleResize: _.debounce(function() {
      this.getDetailLine();
    }, 3000),

    showDurationSelector(rule) {
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2"].includes(rule.type);
    },
    showOrTextSelector(rule) {
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["1", "2", "4", "8"].includes(rule.source);
    },
    //震动喝光照要隐藏less than or
    showAfterOrSelector(rule) {
      return ["32", "64"].includes(rule.source);
    },
    //电量要隐藏or more than
    showBeforeOrSelector(rule) {
      return ["16"].includes(rule.source);
    },

    selectExistedRules() {
      this.$refs.existedRulesRef.showDialog(this.deviceId);
    },
    newRules() {
      this.goPages("/createAlert");
    },
    removeRule(item) {
      console.log("item: ", item);
      let params = {
        deviceId: this.deviceId,
        id: item.id,
      };
      deleteAlarmSettingApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("delete_successful"));
        this.getAlarmRules();
      });
    },
    getFormattedTemp(value) {
      return (
        this.$options.filters.convertTemperature(
          Number(value),
          this.setSelectedTempType
        ) + this.setSelectedTempType
      );
    },
    showTemperatureUnit(rule) {
      console.log("showTemperatureUnit---rule: ", JSON.stringify(rule));
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2", "8", "16", "32", "64"].includes(rule.source);
    },
    changeZoomOption(val) {
      console.log("val: ", val);
      switch (val) {
        case "1":
          this.$refs.chartRef.changeHeight("450px");
          this.zoomValue = 40; //控制makeLine的
          break;
        case "2":
          this.$refs.chartRef.changeHeight("550px");
          this.zoomValue = 50; //控制makeLine的
          break;
        case "3":
          this.$refs.chartRef.changeHeight("650px");
          this.zoomValue = 60; //控制makeLine的
          break;

        default:
          break;
      }
    },
    goShipmentDetail() {
      //  this.$refs.addLoggerRef.showDialog();
      // this.goPages("/loggerDetail", { deviceId: row.deviceId });
      localStorage.setItem("selectedMenuItem", 4);
      this.$store.dispatch("updateSelectedMenuItem", 4);
      let obj = {
        shipmentId: this.deviceBaseInfo.shipmentId,
      };
      console.log("obj: ", JSON.stringify(obj));
      this.goPages("/shipmentDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    attachToShipment() {
      this.$refs.shipmentRef.showDialog(this.deviceId);
    },
    newShipment() {
      localStorage.setItem("selectedMenuItem", 4);
      this.$store.dispatch("updateSelectedMenuItem", 4);
      this.goPages("/createShipment");
    },
    getRowStyle({ row }) {
      console.log("row: ", row.readInd);
      if (row.readInd === 1) {
        return {
          "background-color": "#f5f7fa",
          color: "#c0c4cc",
          cursor: "not-allowed",
        };
      }
      return {};
    },
     rowSelectable(row) {
      console.log("row: ", JSON.stringify(row));
      return row.readInd === 0;
    },
  },
  props: {},
  components: {
    ChangeLoggerNameDialog,
    ChangeLoggerRemarkDialog,
    LoggerStackedAreaChart,
    // ChangeAlarmConditionsDialog,
    TransferDeviceDialog,
    AddDeviceToOrganDialog,
    // AddDeviceToSceneDialog,
    AddCommentDialog,
    SelectExistedRulesDialog,
    AddDeviceToTeamsDialog,
    ShowCanAddShipmentListDialog,
    BatteryInfo,
    FunctionBox,
    StatisticsBox,
    SummaryBox,
    MeasurementsData,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    chartWidth() {
      return `${window.innerWidth / 2}px`;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getAlarmList();
      this.getDetailLine();
    },

    setSelectedTempType(newVal) {
      if (newVal) {
        this.getDetailLine();
      }
    },
    setSelectedLanguage(newVal) {
      console.log("setSelectedLanguage---newVal: ", newVal);
      this.$i18n.locale = newVal;
      this.getDetailLine();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.timezone = this.setSelectedTimeZone;
    let obj = JSON.parse(decodeURIComponent(this.$route.query.data));
    console.log("obj====: ", obj);
    this.deviceId = obj.deviceId;
    console.log("this.deviceId:", this.deviceId);
    this.clickTab(5);
    this.getDetailInfo();
    this.getDetailSummary();
    this.getAlarmRules();
    this.getAlarmList();
    this.getAlarmType();
    this.getAlarmSensor();
    // this.getAlarmConditions();
    console.log("this.chartWidth: ", this.chartWidth);
    this.$refs.chartRef.init(this.option);
    this.changeZoomOption(this.zoomOptionsValue);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {},

  created() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 10px 68px 0 38px;
  display: flex;
  flex-direction: column;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .record-style {
      display: flex;
      align-items: center;

      span {
        font-size: 48px;
        font-weight: 500;
      }
      .edit-name {
        cursor: pointer;
        width: 30px;
        margin: 17px;
      }

      .left-status-box {
        min-width: 145px;
        height: 42px;
        display: flex;
        border-radius: 8px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 24px;
        img {
          width: 25px;
          height: 25px;
          margin-right: 9px;
        }
        span {
          color: white;
          // margin-top: 16px;
          font-size: 20px;
        }
        .alert-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          .text {
            font-size: 24px;
            color: white;
          }
        }
      }
    }
    .left-box {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      width: 48%;

      // .export-box {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: flex-end;
      //   margin-bottom: 20px;
      // }

      .status-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        // margin-top: 10px;

        .point {
          width: 6px;
          height: 6px;
          border-radius: 3px;
          margin-right: 4px;
        }
      }
      .battery-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        .device-id-style {
          color: #333333;
          line-height: 15px;
          font-size: 20px;
        }
        .status-box-blue {
          border-radius: 4px;
          padding: 4px 20px;
          // background: #438af6;
          color: #1a73f5;
          margin: 0 20px;
          font-size: 20px;
        }
        .status-box-gray {
          border-radius: 4px;
          padding: 4px 20px;
          // background: #a0a0a0;
          color: #a0a0a0;
          margin: 0 20px;
          font-size: 20px;
        }
      }
      .time-box {
        display: flex;
        flex-direction: column;
        // align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
        span {
          margin: 5px 40px 0 0;
        }
      }
    }
    .right-box {
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      // position: relative;
      // margin-bottom: 4px;

      .operate-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        top: 10px;
        right: 0;
        margin-right: 70px;

        .btn-box {
          display: flex;
          flex-direction: column;
          // margin-right: -20px;

          .btn-style {
            padding: 8px 16px;
            border-radius: 4px;
            font-size: 14px;
            transition: all 0.3s ease;
            background: #1a72f4;
            border-color: #1a72f4;
            border-radius: 4px;
            font-weight: 500;
            margin: 10px 0 10px 10px;
            color: white;
            width: 240px;
            text-align: center;
            padding: 12px 0 12px 20px;
            font-size: 14px;
            display: inline-block;
            line-height: 1;
          }

          .limits-1 {
            background: #1a72f4;
          }

          .limits-0 {
            background: #c0c2cb;
            border-color: #c0c2cb;
            // pointer-events: none; /* 禁止点击事件 */
            cursor: not-allowed; /* 更改光标样式为不可点击 */
          }
        }
      }
    }
  }

  .table-container {
    border-collapse: collapse; /* 合并边框线 */
    border-spacing: 0; /* 去除单元格间隔 */
    // position: absolute; /* 使用相对定位以便绝对定位子元素 */
    // right: 0; /* 重置right属性 */
    // bottom: -5px; /* 重置bottom属性 */
    border-radius: 8px; /* 设置圆角 */
    overflow: hidden; /* 隐藏超出部分，防止圆角被超出内容破坏 */
    background-color: #fff; /* 背景颜色与表格相同以隐藏间隙 */
    border: 1px solid #bbbbbb; /* 边框，与表格边框相同 */
    // height: 400px;
    // top: 150px;
    margin-top: 150px;

    table {
      width: 700px;
    }
    .gray-cell {
      background-color: #f2f2f2;
      padding: 15px;
      // border: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      img {
        width: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .label {
      width: 100px;
      color: black;
      font-weight: 500;
    }

    .white-cell {
      background-color: #fff;
      padding: 15px;
      // border: 1px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
      img {
        width: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .shipment-detail {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-box {
        text-align: center;
        background: transparent;
        border-radius: 4px;
        border: 1px solid transparent;
        color: #409eff;
        height: 20px;
        line-height: 20px;
        padding: 5px 0;
        cursor: pointer;
      }
      cursor: pointer;
    }
    .shipment-operate {
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-box {
        text-align: center;
        background: #1a72f4;
        border-radius: 4px;
        border: 1px solid #1a72f4;
        color: white;
        height: 20px;
        line-height: 20px;
        padding: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .alert-box {
    background: white;
    padding: 20px;
    border-radius: 8px;

    .table-title {
      margin: 10px 0;
    }

    .alert-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      span {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .rules-box {
    margin: 10px 0;
    background: white;
    padding: 20px;
    border-radius: 8px;

    .rules-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 20px;
      .left {
        display: flex;
        flex-direction: column;
        .font-light {
          color: #9b9b9b;
          font-size: 14px;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        .el-button--primary {
          background: #1a72f4;
          border-color: #1a72f4;
          height: 40px;
        }
      }
    }
    span {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .echart-box {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }
    .zoom-style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .tab-box {
          margin: 10px 0;
          display: flex;
          background: white;
          flex-direction: row;
          align-items: center;
          line-height: 40px;
          height: 20px;
          padding-right: 10px;
          .zoom-box {
            margin-left: 20px;
            font-size: 16px;
          }
        }
        .tab-item {
          font-size: 14px;
          text-align: center;
          color: white;
          justify-content: center;
          background: #bbbaba;
          height: 24px;
          line-height: 24px;
          width: 60px;
          border-radius: 2px;
          margin: 0 5px;
          cursor: pointer;
        }

        .is-active {
          background: #1a72f4;
          font-size: 16px;
          font-weight: Normal;
          text-align: center;
          color: #ffffff;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .date-tip {
          font-size: 18px;
          margin: 0 10px;
        }
      }
    }
    .chart {
      position: relative;
      top: 10px;
      .additional-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        height: 14px;
        line-height: 14px;

        margin-top: 18px;
        img {
          width: 15px;
          height: 15px;
        }
        .all-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          span {
            color: #333333;
            font-size: 14px;
            margin-top: 2px;
          }
        }
        .not-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .high {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #fc5c5b;
              font-size: 14px;
              margin-top: 2px;
            }
          }
          .low {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #6464ff;
              font-size: 14px;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
.chart {
  width: 97%;
  color: white;
}
.comment-box {
  display: flex;
  justify-content: center;

  .add-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 32px;
  }
}
.rule-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(187, 187, 187, 1);
  padding: 5px 10px;
  height: 59px;
  line-height: 59px;
  border-radius: 5px;
  margin-left: -120px;
  img {
    width: 33px;
    height: 33px;
  }
  .selects-container {
    display: flex;
    gap: 8px;
    align-items: center;
    .select-item {
      width: 220px;
      height: 47px;
      line-height: 47px;
    }
    .input-item {
      width: 100px;
      height: 47px;
      line-height: 47px;
    }
  }

  /deep/.el-select .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::placeholder {
    color: #606266;
  }
}
// /deep/.el-select .el-input__inner {
// height: 30px;
// width: 80px;
// }
</style>
